import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public platform: Platform,
    private splashScreen: SplashScreen
  ) {}

  initializeApp() {
    this.platform.ready().then(() => {
        this.splashScreen.hide();
        alert('hello !');
    })
  }

}
